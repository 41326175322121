import { Component } from "solid-js";
import { Toast, toaster } from "@kobalte/core";
import { VsChromeClose } from "solid-icons/vs";
import styles from "./Toast.module.css";
import { Portal } from "solid-js/web";

export const ToastArea: Component = () => {
  return (
    <Portal>
      <Toast.Region swipeDirection="right" pauseOnInteraction>
        <Toast.List class={styles.list} />
      </Toast.Region>
    </Portal>
  );
};

export function showToast({ message }: { message: string }): number {
  return toaster.show((props) => (
    <Toast.Root toastId={props.toastId} class={styles.toast}>
      <div class={styles.content}>
        <div>
          <Toast.Description>{message}</Toast.Description>
        </div>
        <Toast.CloseButton class={styles.closeButton}>
          <VsChromeClose />
        </Toast.CloseButton>
      </div>
    </Toast.Root>
  ));
}
