import receiptline, { Printer } from "receiptline";
import { reconcile } from "solid-js/store";
import { OrdersToPrint, OrderToPrint, Result } from "~/common-types";
import * as PrintersMod from "~/server/printers";
import * as OrdersMod from "~/server/orders";
import * as OrdersPrintersCycles from "~/server/orders_printersCycles";
import { createClient, parseAccessToken } from "~/supabase";
import { DateTime } from "luxon";
import { listenPrintPreBill, PreBill } from "~/events";

type AsyncFunction = () => Promise<void>;

class AsyncQueue {
  private queue: AsyncFunction[];
  private running: boolean;
  private delay: number;

  constructor({ delay = 0 }: { delay: number }) {
    this.queue = []; // Holds the async operations
    this.running = false; // Indicates if an operation is currently running
    this.delay = delay; // Delay between operations
  }

  // Adds an async function to the queue
  push(asyncFunction: AsyncFunction): void {
    this.queue.push(asyncFunction);
    this.run(); // Attempt to run the next operation in the queue
  }

  flush(): Promise<void> {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.queue.length === 0 && !this.running) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  }

  // Internal method to run the next operation if not currently running any
  private async run(): Promise<void> {
    if (!this.running && this.queue.length > 0) {
      this.running = true; // Mark as running
      const operation = this.queue.shift(); // Get the next operation

      if (operation) {
        try {
          await operation(); // Wait for the operation to finish
          if (this.delay > 0) {
            await new Promise((resolve) => setTimeout(resolve, this.delay));
          }
        } catch (error) {
          console.error("Operation failed:", error);
        }
        this.running = false; // Reset running status
        this.run(); // Check if there are more operations to run
      }
    }
  }
}

const LocalStorageKey = "ordersToPrint";

async function printData(
  device: USBDevice,
  data: BufferSource,
): Promise<Result> {
  try {
    await device.open();
    if (device.configuration === null) await device.selectConfiguration(1);
    await device.claimInterface(0);
    const alternateInterface = device.configuration!.interfaces[0].alternate;
    const endpoint = alternateInterface.endpoints.find(
      (endpoint) => endpoint.direction === "out",
    );
    if (endpoint) {
      await device.selectAlternateInterface(0, 0);
      await device.transferOut(endpoint.endpointNumber, data);
    }
    await device.close();
    return { ok: true };
  } catch (error) {
    console.error("Error sending data to the printer:", error);
    await device.close();
    return { ok: false, error: "Error sending data to the printer:" + error };
  }
}

async function execWithLocalStorage({
  fn,
  localStorage,
  localStorageKey,
  localStorageValue,
}: {
  fn: () => Promise<Result<unknown, unknown>>;
  localStorage: Storage;
  localStorageKey: string;
  localStorageValue: Record<string, unknown>;
}) {
  let localStorageInitialValue: OrdersToPrint;
  try {
    localStorageInitialValue = JSON.parse(
      localStorage.getItem(LocalStorageKey) ?? "{}",
    );
  } catch (error) {
    console.error("Error parsing orders being printed", error);
    localStorageInitialValue = {};
  }

  const localStorageValueToUpdate = reconcile(localStorageValue)(
    localStorageInitialValue,
  );

  localStorage.setItem(
    localStorageKey,
    JSON.stringify(localStorageValueToUpdate),
  );

  const execResult = await fn();
  if (execResult.ok) {
    localStorage.setItem(
      localStorageKey,
      JSON.stringify(
        reconcile(
          Object.keys(localStorageValue).reduce(
            (acc, key) => ({ ...acc, [key]: undefined }),
            {},
          ),
        )(localStorageValueToUpdate),
      ),
    );
  } else {
    console.error("Error executing async function", execResult.error);
  }
}

async function printOrdersAndUpdateDB(params: {
  supabase: ReturnType<typeof createClient>;
  localStorage: Storage;
  newOrdersPrintersCycle: typeof OrdersPrintersCycles.newOrdersPrintersCycle;
  printer: USBDevice;
  printersCycle: { id: string; name: string | null };
  companyId: string;
  ordersToPrint: { [orderId: string]: OrderToPrint };
}) {
  // console.log("printOrdersAndUpdateDB");
  const printResult: { [order_id: string]: true } = {};

  for (const order of Object.values(params.ordersToPrint)) {
    const productsByCourse = order.products
      .reduce<
        {
          course_order: number;
          course_name: string;
          products: OrderToPrint["products"];
        }[]
      >(
        (acc, product) => {
          if (product.course_name != null) {
            // has course - create or add to the course
            const course = acc.find(
              ({ course_name }) => course_name === product.course_name,
            );
            if (course) {
              course.products.push(product);
            } else {
              acc.push({
                course_order: product.course_order ?? 0,
                course_name: product.course_name,
                products: [product],
              });
            }
          } else {
            // no course - add to the first empty course
            acc[0].products.push(product);
          }
          return acc;
        },
        [{ course_order: Infinity, course_name: "", products: [] }],
      )
      .sort((a, b) => a.course_order - b.course_order);

    const hasOnlyEmptyCourse =
      productsByCourse.length === 1 && productsByCourse[0].course_name === "";

    const courseNameTemplate = (courseName: string) =>
      courseName
        ? "\n{width:auto;}\n-\n| ^^^`" + courseName + " |\n-"
        : "{width:auto;}\n-\n\n";

    const productsTemplate = (products: OrderToPrint["products"]) => `
      {width:2,2,*,6;} 
      ${products
        .map((product) => {
          const firstLineDescription = product.description.split("\n")[0]
            ? product.description.split("\n")[0].replace(/\s?\(.*?€.*?\)/g, "")
            : undefined; // Prendi la prima riga della descrizione
          const secondLineDescription = product.description.split("\n")[1]
            ? product.description.split("\n")[1].replace(/\s?\(.*?€.*?\)/g, "")
            : undefined; // Prendi la seconda riga della descrizione
          const thirdLineDescription = product.description.split("\n")[2]
            ? product.description.split("\n")[2].replace(/\s?\(.*?€.*?\)/g, "")
            : undefined; // Prendi la terza riga della descrizione
          const fourthLineDescription = product.description.split("\n")[3]; // Prendi la seconda riga della descrizione

          const formattedPrice =
            (product.price * product.quantity).toLocaleString("it-IT", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }) + " €";

          const concatenatedDescription =
            `^^^"${product.quantity.toFixed(
              0,
            )} | x |^^^"${firstLineDescription} | "${formattedPrice}` +
            (secondLineDescription
              ? `\n|                                |     |^^"${secondLineDescription}`
              : "") +
            (thirdLineDescription
              ? `\n|                                |     |^^${thirdLineDescription}`
              : "") +
            (fourthLineDescription
              ? `\n|                                |     |^^${fourthLineDescription}`
              : "");

          return concatenatedDescription;
        })
        .join("\n\n")}
      `;

    const receiptTemplate = `
| ^^^${order.area_name} |
-

| ^^^^"${order.table_name} |

-

${
  hasOnlyEmptyCourse
    ? productsTemplate(order.products)
    : productsByCourse.map(
        (course) =>
          courseNameTemplate(course.course_name) +
          productsTemplate(course.products),
      )
}

{width:auto;}
${DateTime.fromISO(order.created_at.toISOString()).toFormat(
  "dd/MM/yyyy - HH:mm",
)} |
${order.user_name} |

`;

    const printerParams: Printer = {
      cpl: 48,
      encoding: "multilingual",
      spacing: true,
      command: "generic",
    };

    const printCommands = Buffer.from(
      receiptline.transform(receiptTemplate, printerParams),
      "binary",
    );

    const res = await printData(params.printer, printCommands);

    if (res.ok) {
      printResult[order.order_id] = true;
    }
  }

  const successfullyPrintedOrders = Object.values(params.ordersToPrint).filter(
    (op) => printResult[op.order_id],
  );

  if (successfullyPrintedOrders.length > 0) {
    // console.log("printOrdersAndUpdateDB [printResult]", printResult);
    try {
      await execWithLocalStorage({
        fn: async () => {
          const ordersToSave = successfullyPrintedOrders.map((order) => ({
            order_id: order.order_id,
            printersCycle_id: params.printersCycle.id,
            company_id: params.companyId,
          }));
          const result = await params.newOrdersPrintersCycle(
            params.supabase,
            ordersToSave,
          );
          if (result.error) {
            return { ok: false, error: result.error.message };
          } else {
            return { ok: true, value: undefined };
          }
        },
        localStorage: params.localStorage,
        localStorageKey: LocalStorageKey,
        localStorageValue: {
          [params.printersCycle.id]: Object.keys(params.ordersToPrint).reduce(
            (acc, orderId) => ({ ...acc, [orderId]: true }),
            {},
          ),
        },
      });
    } catch (error) {
      console.error("Error saving orders to print", error);
    }
  } else {
    console.error("print error");
  }
}

async function printPreBill(printer: USBDevice, preBill: PreBill) {
  const preBillTemplate = `
| ^^^"_PRECONTO |

${preBill.area_name ? `| ^^${preBill.area_name} - ` : "| ^^"}${
    preBill.table_name
  } |


{width:2,2,*,6;}
${preBill.products
  .map((product) => {
    const firstLineDescription = product.description.split("\n")[0]; // Prendi la prima riga della descrizione
    const secondLineDescription = product.description.split("\n")[1]; // Prendi la seconda riga della descrizione
    const thirdLineDescription = product.description.split("\n")[2]; // Prendi la seconda riga della descrizione
    const fourthLineDescription = product.description.split("\n")[3]; // Prendi la seconda riga della descrizione

    const concatenatedDescription =
      `${product.quantity.toFixed(
        0,
      )} | x |"${firstLineDescription} | "${product.totalPrice
        .toFixed(2)
        .replace(".", ",")}` +
      (secondLineDescription
        ? `\n|                                |     |${secondLineDescription}`
        : "") +
      (thirdLineDescription
        ? `\n|                                |     |${thirdLineDescription}`
        : "") +
      (fourthLineDescription
        ? `\n|                                |     |${fourthLineDescription}`
        : "");

    // return `^^${product.quantity.toFixed(0)} | ^^x |^^${firstLineDescription} | ^^${(product.price * product.quantity).toFixed(2)} \n
    //         |                                |     |${secondLineDescription} \n
    //         |                                |     |${thirdLineDescription}`;

    return concatenatedDescription;
  })
  .join("\n")}

{width:auto;}
| ^^^${preBill.total.toFixed(2).replace(".", ",")}

${preBill.datetime} |

`;

  const printerParams: Printer = {
    cpl: 48,
    encoding: "multilingual",
    spacing: true,
    command: "generic",
  };

  const printCommands = Buffer.from(
    receiptline.transform(preBillTemplate, printerParams),
    "binary",
  );

  return printData(printer, printCommands);
}

async function doPrint({
  printersCycles,
  localStorage,
  orders,
  supabase,
  companyId,
  connectedPrinter,
  newOrdersPrintersCycle,
}: {
  printersCycles: { id: string; name: string | null }[];
  localStorage: Storage;
  orders: OrdersToPrint;
  supabase: ReturnType<typeof createClient>;
  companyId: string;
  connectedPrinter: USBDevice;
  newOrdersPrintersCycle: typeof OrdersPrintersCycles.newOrdersPrintersCycle;
}) {
  // console.log("doPrint [orders]", orders);
  if (orders == null) {
    console.log("No order to print");
    return;
  }
  let ordersBeingPrinted: OrdersToPrint;
  try {
    ordersBeingPrinted = JSON.parse(
      localStorage.getItem(LocalStorageKey) ?? "{}",
    );
  } catch (error) {
    console.error("Error parsing orders being printed", error);
    ordersBeingPrinted = {};
    return;
  }

  for (const printersCycle of printersCycles) {
    if (orders[printersCycle.id] == null) {
      // console.log(
      //   "doPrint",
      //   `No orders for printersCycle ${printersCycle.name} ${printersCycle.id}`,
      // );
      continue;
    }
    const ordersToPrint: { [orderId: string]: OrderToPrint } = Object.keys(
      orders[printersCycle.id],
    ).reduce<{ [orderId: string]: OrderToPrint }>((acc, key) => {
      if (ordersBeingPrinted[printersCycle.id]?.[key] != null) {
        return acc;
      }
      return {
        ...acc,
        [key]: orders[printersCycle.id][key],
      };
    }, {});
    if (Object.keys(ordersToPrint).length === 0) {
      // console.log(
      //   "doPrint",
      //   `No order to be printed left for printersCycle ${printersCycle.name} ${printersCycle.id}`,
      // );
      continue;
    }

    await printOrdersAndUpdateDB({
      supabase,
      companyId,
      localStorage,
      printer: connectedPrinter,
      printersCycle,
      ordersToPrint,
      newOrdersPrintersCycle,
    });
  }
}

export async function startPrinter({
  supabase,
  localStorage,
  getDevices,
  getPrinters,
  getPrinterCycles,
  subscribeToOrderChanges,
  getOrdersToPrint,
  newOrdersPrintersCycle,
  showToast,
  queueDelay = 1000,
}: {
  supabase: ReturnType<typeof createClient>;
  localStorage: Storage;
  getDevices: () => Promise<USBDevice[]>;
  getPrinters: typeof PrintersMod.getPrinters;
  getPrinterCycles: typeof PrintersMod.getPrinterCycles;
  subscribeToOrderChanges: typeof OrdersMod.subscribeToOrderChanges;
  getOrdersToPrint: typeof OrdersMod.getOrdersToPrint;
  newOrdersPrintersCycle: typeof OrdersPrintersCycles.newOrdersPrintersCycle;
  showToast: (params: { message: string }) => number;
  queueDelay?: number;
}): Promise<(() => Promise<void>) | undefined> {
  const queue = new AsyncQueue({ delay: queueDelay });

  async function printOrders(
    connectedPrinter: USBDevice,
    remotePrinterId: string,
  ) {
    if (connectedPrinter == null || remotePrinterId == null) {
      return;
    }

    // get orders printers cycles for the selected printer
    const session = await supabase.auth.getSession();
    if (session == null || session.error || session.data.session == null) {
      console.error(`Session data is null`, session);
      return;
    }
    const token = parseAccessToken(session.data.session.access_token);
    const companyId = token.user_metadata.companies[0].company_id;
    const { data: printersCycles, error } = await getPrinterCycles(
      supabase,
      remotePrinterId,
    );
    if (error) {
      showToast({ message: "Errore nel recupero dei cicli di stampa" });
      return;
    }

    const fetchOrdersToPrint = async () => {
      console.log("fetchOrdersToPrint");
      const ordersRes = await getOrdersToPrint(
        supabase,
        printersCycles.map((printersCycle) => printersCycle.id),
      );

      return doPrint({
        printersCycles,
        localStorage,
        orders: ordersRes,
        supabase,
        companyId,
        connectedPrinter,
        newOrdersPrintersCycle,
      });
    };

    const unsubscribeFromOrderChanges = subscribeToOrderChanges(() =>
      queue.push(fetchOrdersToPrint),
    );

    queue.push(fetchOrdersToPrint);

    const unsubscribeFromPrintPreBuild = listenPrintPreBill((preBill) => {
      queue.push(async () => {
        const result = await printPreBill(connectedPrinter, preBill);
        if (!result.ok) {
          showToast({ message: "Errore nella stampa del Pre Conto" });
        }
      });
    });

    return () => {
      unsubscribeFromOrderChanges();
      unsubscribeFromPrintPreBuild();
    };
  }

  const [localDevicesRes, remotePrintersRes] = await Promise.all([
    (async function getLocalPrinter(): Promise<Result<USBDevice[], string>> {
      const localDevices = await getDevices();
      if (localDevices.length === 0) {
        return { ok: false, error: "no connected printer found" };
      }
      return { ok: true, value: localDevices };
    })(),
    (async function getRemotePrinters(): Promise<
      Result<
        {
          id: string;
          vendorId: number;
          productId: number;
        }[],
        string
      >
    > {
      const { data: remotePrinters, error } = await getPrinters(supabase);
      if (error) {
        return { ok: false, error: "Error retrieving remote printers" };
      }
      return { ok: true, value: remotePrinters };
    })(),
  ]);

  if (
    (remotePrintersRes.ok === false || remotePrintersRes.value.length > 0) &&
    localDevicesRes.ok === false
  ) {
    console.error(localDevicesRes.error);
    console.error(remotePrintersRes.ok ? "" : remotePrintersRes.error);
    console.log(
      "La stampante collegata non risulta connessa a questo computer",
    );
    return;
  }
  const localDevices = localDevicesRes.ok ? localDevicesRes.value : [];
  const remotePrinters = remotePrintersRes.ok ? remotePrintersRes.value : [];
  let unsubscribe: (() => void) | undefined = undefined;
  if (remotePrinters.length > 0) {
    for (const localDevice of localDevices) {
      for (const remotePrinter of remotePrinters) {
        if (
          localDevice.vendorId === remotePrinter.vendorId &&
          localDevice.productId === remotePrinter.productId
        ) {
          const maybeUnsubscribe = await printOrders(
            localDevice,
            remotePrinter.id,
          );
          if (maybeUnsubscribe != null) {
            unsubscribe = maybeUnsubscribe;
          }
          break;
        }
      }
    }
    if (unsubscribe == null) {
      console.log("nessuna stampante collegata risulta connessa al momento");
      return;
    }
  } else {
    console.log("no printers found");
  }

  return async function stopPrinter() {
    console.log("stopPrinter");
    if (typeof unsubscribe === "function") unsubscribe();
    await queue.flush();
  };
}
