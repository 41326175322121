import {
  Component,
  createSignal,
  Match,
  onCleanup,
  onMount,
  Switch,
} from "solid-js";
import { createClient } from "~/supabase";
import { showToast } from "~/components/Toast";
import { subscribeToOrderChanges, getOrdersToPrint } from "~/server/orders";
import { getPrinterCycles, getPrinters } from "~/server/printers";
import { newOrdersPrintersCycle } from "~/server/orders_printersCycles";
import { startPrinter } from "./Print";
import Button from "./Button";
import { IoPrint, IoRefresh } from "solid-icons/io";
import styles from "./Printer.module.css";

const Printer: Component = () => {
  const [isSelectPrinterButtonDisabled, setIsSelectPrinterButtonDisabled] =
    createSignal(true);
  const [hasConnectedPrinter, setHasConnectedPrinter] = createSignal(false);

  function initPrint() {
    return startPrinter({
      supabase: createClient(),
      localStorage,
      getDevices: async () => navigator.usb.getDevices(),
      getPrinters,
      getPrinterCycles,
      subscribeToOrderChanges,
      getOrdersToPrint,
      newOrdersPrintersCycle,
      showToast,
    });
  }

  let cleanup: (() => Promise<void>) | undefined;
  let refreshInterval: NodeJS.Timer | undefined = undefined;
  onMount(async () => {
    if (
      typeof navigator.usb?.requestDevice !== "function" ||
      typeof navigator.usb?.getDevices !== "function"
    ) {
      console.log("the device does not support WebUSB API");
      return;
    }

    if (typeof navigator.usb?.requestDevice === "function") {
      setIsSelectPrinterButtonDisabled(false);
    }

    cleanup = await initPrint();

    if (cleanup) {
      setHasConnectedPrinter(true);
    }

    refreshInterval = setInterval(
      async () => {
        console.log("refreshInterval: Refresh printer");

        if (cleanup) {
          console.log("refreshInterval: Await printer cleanup");
          await cleanup();
        }

        console.log("refreshInterval: Init printer");
        cleanup = await initPrint();
      },
      5 * 60 * 1000,
    );
  });

  onCleanup(async () => {
    refreshInterval && clearInterval(refreshInterval);
    if (cleanup) {
      console.log("onCleanup: Await printer cleanup");
      await cleanup();
    }
  });

  const selectPrinter = async () => {
    try {
      const device = await navigator.usb.requestDevice({ filters: [] });
      if (device == null) {
        showToast({ message: "Nessuna stampante collegata" });
        return;
      }
      localStorage.setItem(
        "selectedPrinter",
        JSON.stringify({
          vendorId: device.vendorId,
          productId: device.productId,
          protocolCode: device.deviceProtocol,
          serialNumber: device.serialNumber,
        }),
      );
      if (cleanup) {
        console.log("selectPrinter: Await printer cleanup");
        await cleanup();
      }

      console.log("selectPrinter: Init printer");
      cleanup = await initPrint();
    } catch (error) {
      console.error("Error selecting a printer:", error);
    }
  };

  const refreshPrinter = async () => {
    if (cleanup) {
      console.log("refreshPrinter: Await printer cleanup");
      await cleanup();
    }

    console.log("refreshPrinter: Init printer");
    cleanup = await initPrint();
  };

  return (
    <Switch>
      <Match when={hasConnectedPrinter()}>
        <Button
          onClick={refreshPrinter}
          variant="icon"
          disabled={isSelectPrinterButtonDisabled()}
        >
          <IoRefresh
            class={`${styles.button} ${
              isSelectPrinterButtonDisabled() ? styles.buttonDisabled : ""
            }`}
          />
        </Button>
      </Match>
      <Match when={!hasConnectedPrinter()}>
        <Button
          onClick={selectPrinter}
          variant="icon"
          disabled={isSelectPrinterButtonDisabled()}
        >
          <IoPrint
            class={`${styles.button} ${
              isSelectPrinterButtonDisabled() ? styles.buttonDisabled : ""
            }`}
          />
        </Button>
      </Match>
    </Switch>
  );
};

export default Printer;
