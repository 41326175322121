import { retry } from "~/backoff";
import { createClient } from "~/supabase";

export const getPrinters = retry(
  async (supabase: ReturnType<typeof createClient>) => {
    console.log("API: getPrinters");
    return supabase
      .from("printers")
      .select("id, vendorId, productId, serialNumber");
  },
  3,
);

export const getPrinterCycles = retry(
  async (supabase: ReturnType<typeof createClient>, id: string) => {
    console.log("API: getPrinterCycles", "printer_id", id);
    return supabase
      .from("printersCycles")
      .select("*")
      .eq("printer_id", id)
      .order("order_id");
  },
  3,
);
