import { DateTime } from "luxon";
import { retry } from "~/backoff";
import {
  Order,
  OrderItem,
  OrderState,
  OrdersToPrint,
  OrderToPrint,
  Result,
} from "~/common-types";
import { createClient } from "~/supabase";

export const getOrderByTableName = retry(async (tableName: string) => {
  console.log("API: getOrderByTableName", tableName);
  const supabase = createClient();
  const tablesRes = await supabase
    .from("tables")
    .select("*, orders(*, orders_products(*, products(name)))")
    .eq("name", tableName)
    .neq("orders.state", "CANCELLED")
    .eq("orders.paid", false)
    .single();
  if (tablesRes.error) {
    console.error("error fetching tables", tablesRes.error?.message);
    throw new Error("error fetching tables");
  } else {
    const orders: Order[] =
      tablesRes.data.orders
        .sort(
          (a, b) =>
            DateTime.fromISO(a.created_at).toMillis() -
            DateTime.fromISO(b.created_at).toMillis(),
        )
        .map((order) => ({
          order_id: order.id,
          created_at: order.created_at,
          table_name: tablesRes.data.name,
          state: order.state,
          items: order.orders_products
            .map((op) => ({
              id: op.id,
              product_id: op.product_id ?? undefined,
              product_name: op.products?.name,
              description: op.description ?? "",
              quantity: op.quantity,
              payed_quantity: op.payed_quantity,
              price: op.price || 0,
            }))
            .filter((op) => op.payed_quantity !== op.quantity),
        })) ?? [];
    return orders;
  }
}, 3);

export const getOrdersToPrint = retry(
  async (
    supabase: ReturnType<typeof createClient>,
    printerCycleIds: string[],
  ): Promise<OrdersToPrint> => {
    console.log("API: getOrdersToPrint", "printerCycleIds", printerCycleIds);

    const result = await Promise.all(
      printerCycleIds.map(async (printerCycleId) => {
        const ordersRes = await supabase.rpc("get_orders_to_print", {
          printer_cycle_id: printerCycleId,
        });

        if (ordersRes.error) {
          console.error(
            "error fetching orders to_prepare",
            ordersRes.error?.message,
          );
          throw new Error("error fetching orders to_prepare");
        } else {
          return ordersRes.data.reduce<{
            [order_id: string]: OrderToPrint;
          }>((acc, orderRow) => {
            if (!acc[orderRow.id]) {
              acc[orderRow.id] = {
                order_id: orderRow.id,
                area_name: orderRow.area_name,
                table_name: orderRow.table_name,
                user_name: orderRow.user_name,
                created_at: DateTime.fromISO(orderRow.created_at).toJSDate(),
                products: [],
              };
            }
            acc[orderRow.id].products.push({
              description: orderRow.description,
              quantity: orderRow.quantity,
              price: orderRow.price,
              course_name: orderRow.course_name,
              course_order: orderRow.course_order,
            });
            return acc;
          }, {});
        }
      }),
    );

    return result.reduce<OrdersToPrint>((acc, ordersOfPrinterCycle, index) => {
      return { ...acc, [printerCycleIds[index]]: ordersOfPrinterCycle };
    }, {});
  },
  3,
);

export function subscribeToOrderProductsChanges(onNewData: () => void) {
  console.log("API: subscribeToOrderProductsChanges");
  const supabase = createClient();
  const channel = supabase
    .channel("orders_changes")
    .on(
      "postgres_changes",
      {
        event: "*",
        schema: "public",
        table: "orders_products",
      },
      async () => {
        onNewData();
      },
    )
    .subscribe();

  return function unsubscribe() {
    supabase.removeChannel(channel);
  };
}

export function subscribeToOrderChanges(onNewData: () => void) {
  console.log("API: subscribeToOrderChanges");
  const supabase = createClient();
  const channel = supabase
    .channel("orders")
    .on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "orders",
      },
      async () => {
        onNewData();
      },
    )
    .subscribe();

  return function unsubscribe() {
    supabase.removeChannel(channel);
  };
}

/**
 * Updates the payed quantity of the order products
 * @param orderProducts
 * @param orderProducts.id The order product id
 * @param orderProducts.quantity The new payed quantity. It's the total payed quantity, not the increment.
 */
export const updateOrderProduct = retry(
  async (orderProducts: { id: string; quantity: number }[]) => {
    const supabase = createClient();
    return supabase.rpc("update_orders_products_payed_quantity", {
      payload: orderProducts,
    });
  },
  3,
);

export async function newOrder(params: {
  tableName: string;
  orderItems: OrderItem[];
  orderState: OrderState;
  paid: boolean;
  toPrint: boolean;
}): Promise<Result<{ order_id: string }>> {
  const supabase = createClient();
  const { error, data } = await supabase.rpc("create_new_order", {
    items: params.orderItems,
    table_name: params.tableName,
    order_state: params.orderState,
    paid: params.paid,
    to_print: params.toPrint,
  });

  if (error) {
    console.error(error);
    return { ok: false, error: error.message };
  } else {
    if (data && typeof data === "object" && (data as any)["ok"] != null) {
      const typedData = data as Result<{ order_id: string }>;
      if (typedData.ok) {
        return { ok: true, value: typedData.value };
      } else {
        console.error(typedData.error);
        return { ok: false, error: typedData.error };
      }
    } else {
      return { ok: false, error: "Unknown error" };
    }
  }
}

export async function updateOrdersTable(params: {
  orderIds: string[];
  tableId: string;
}): Promise<Result> {
  const supabase = createClient();
  const { error } = await supabase
    .from("orders")
    .update({ table_id: params.tableId })
    .in("id", params.orderIds);
  if (error) {
    console.error(error);
    return { ok: false, error: error.message };
  } else {
    return { ok: true };
  }
}
