export type PreBill = {
  area_name: string;
  table_name: string;
  total: number;
  datetime: string;
  products: {
    quantity: number;
    description: string;
    totalPrice: number;
  }[];
};

const PRINT_PRE_BILL_ENVENT = "PrintPreBill";

export const dispatchPrintPreBill = (payload: PreBill) => {
  document.dispatchEvent(
    new CustomEvent(PRINT_PRE_BILL_ENVENT, {
      detail: payload,
    }),
  );
};

export const listenPrintPreBill = (callback: (payload: PreBill) => void) => {
  const handleCustomAction = (event: CustomEvent) => {
    callback(event.detail);
  };

  document.addEventListener(
    PRINT_PRE_BILL_ENVENT,
    handleCustomAction as EventListener,
  );

  return () => {
    document.removeEventListener(
      "custom-action",
      handleCustomAction as EventListener,
    );
  };
};
