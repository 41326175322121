import { retry } from "~/backoff";
import { createClient } from "~/supabase";

export const newOrdersPrintersCycle = retry(
  async (
    supabase: ReturnType<typeof createClient>,
    ordersToSave: {
      order_id: string;
      printersCycle_id: string;
      company_id: string;
    }[],
  ) => {
    console.log("API: newOrdersPrintersCycle");

    const result = await supabase
      .from("orders_printersCycles")
      .insert(ordersToSave);

    return result;
  },
  3,
);
